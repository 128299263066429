import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import { API_URL } from '@/config';

export default {
  // getReports(ctx) {
  //   ctx.$store.commit('toggleLoadingReports');
  //   Vue.axios.post(`${API_URL || ''}/api/report`, { action: 'R' }).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         ctx.$store.commit('setReportsList', response.data.data.itemslist);
  //         ctx.$store.commit('toggleLoadingReports');
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //         ctx.$store.commit('toggleLoadingReports');
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.$store.commit('toggleLoadingReports');
  //       ctx.error = err;
  //     }
  //   );
  // },
  getReports(ctx) {
    ctx.$store.commit('toggleLoadingReports');
    const payload = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
    };
    if (ctx.filters?.location) {
      if (ctx.filters.location === 'All locations') {
        payload.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        payload.with_childs = true;
      } else {
        payload.location = ctx.filters.location;
        payload.with_childs = ctx.filters.with_childs;
      }
    }
    Vue.axios.post(`${API_URL || ''}/api/report`, payload).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setReportsList', response.data.data.itemslist);
          ctx.$store.commit('toggleLoadingReports');
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('toggleLoadingReports');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingReports');
        ctx.error = err;
      }
    );
  },
  createReport(ctx, report) {
    if (report.collect_period === 'day') {
      report.action_time = `0 ${ctx.cronStringDay} * * *`;
    } else if (report.collect_period === 'week') {
      report.action_time = `0 ${ctx.cronStringDay} * * ${ctx.cronStringWeek}`;
    } else if (report.collect_period === 'month') {
      report.action_time = `0 ${ctx.cronStringDay} ${ctx.cronStringMonth} * *`;
    }

    ctx.$store.commit('toggleMuteReportEdit');

    Vue.axios.post(`${API_URL || ''}/api/report`, { action: 'C', items: { 0: report } }).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.resetData();
          this.getReports(ctx);
          VueNotifications.success({ message: ctx.$t('reports.notificationReportCreatedSuccessfully') });
          ctx.$store.commit('toggleMuteReportEdit');
        } else if (response.data.status === 'error') {
          ctx.resetData();
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('toggleMuteReportEdit');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleMuteReportEdit');
      }
    );
  },
  updateReport(ctx, report) {
    if (report.collect_period === 'day') {
      report.action_time = `0 ${ctx.cronStringDay} * * *`;
    } else if (report.collect_period === 'week') {
      report.action_time = `0 ${ctx.cronStringDay} * * ${ctx.cronStringWeek}`;
    } else if (report.collect_period === 'month') {
      report.action_time = `0 ${ctx.cronStringDay} ${ctx.cronStringMonth} * *`;
    }

    ctx.$store.commit('toggleMuteReportEdit');

    const updateReport = { action: 'U', items: { [report.id]: report } };
    Vue.axios.post(`${API_URL || ''}/api/report`, updateReport).then(
      (response) => {
        if (response.data.status === 'success') {
          VueNotifications.success({ message: ctx.$t('reports.notificationReportUpdatedSuccessfully') });
          ctx.resetData();
          this.getReports(ctx);
          ctx.$store.commit('toggleMuteReportEdit');
        } else if (response.data.status === 'error') {
          ctx.resetData();
          ctx.$store.commit('toggleMuteReportEdit');
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleMuteReportEdit');
      }
    );
  },
  deleteReport(ctx, report) {
    ctx.$store.commit('toggleMuteReportDelete');
    const deleteReportsQuery = { action: 'D', items: { [report.id]: {} } };

    Vue.axios.post(`${API_URL || ''}/api/report`, deleteReportsQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.resetData();
          this.getReports(ctx);
          VueNotifications.success({ message: ctx.$t('reports.notificationReportDeletedSuccessfully') });
          ctx.$store.commit('toggleMuteReportDelete');
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('toggleMuteReportDelete');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteReportDelete');
        ctx.error = err;
      }
    );
  }
};
